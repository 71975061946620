import { LIST_CABINE_SUCCESS } from "redux/actions/cabines/type";
import { DELETE_CABINE_SUCCESS } from "redux/actions/cabines/type";
import { DELETE_ALL_CABINES_SUCCESS } from "redux/actions/cabines/type";
import { UPDATE_CABINE_SUCCESS } from "redux/actions/cabines/type";
import { UPDATE_CABINE_FAIL } from "redux/actions/cabines/type";
import { DELETE_ALL_CABINES_FAIL } from "redux/actions/cabines/type";
import { DELETE_CABINE_FAIL } from "redux/actions/cabines/type";
import { LIST_CABINE_FAIL } from "redux/actions/cabines/type";
import { CREATE_CABINE_FAIL } from "redux/actions/cabines/type";
import { CREATE_CABINE_SUCCESS } from "redux/actions/cabines/type";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  cabines: [],
  cabine: null,
};

const cabineReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_CABINE_SUCCESS:
      return {
        ...state,
        cabines: payload,
      };
    case LIST_CABINE_FAIL:
      return {
        ...state,
      };
    case CREATE_CABINE_SUCCESS:
      return {
        ...state,
        cabines: [...state.cabines, payload],
      };
    case CREATE_CABINE_FAIL:
      return {
        ...state,
      };
    case DELETE_CABINE_SUCCESS:
      return {
        ...state,
        cabines: state.cabines.filter(({ id }) => id !== payload),
      };
    case DELETE_CABINE_FAIL:
      return {
        ...state,
      };
    case DELETE_ALL_CABINES_SUCCESS:
      return {
        ...state,
        cabines: [],
      };
    case DELETE_ALL_CABINES_FAIL:
      return {
        ...state,
      };
    case UPDATE_CABINE_SUCCESS:
      console.log(payload, "edit cabine paylaod", state.cabines);
      return {
        ...state,
        cabines: state.cabines.map((cabine) => {
          console.log(cabine.id === payload.id, "dddddddddddd");
          if (cabine.id === payload.id) {
            return {
              ...cabine,
              ...payload,
            };
          } else {
            return cabine;
          }
        }),
      };
    case UPDATE_CABINE_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default cabineReducer;
