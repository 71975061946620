import http from "./http-common";

const createTarif = (data) => {
  return http
    .post("/admin/tarif/create", data)
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

const listTarif = () => {
  return http
    .get("/admin/tarif/list")
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

const deleteAll = () => {
  return http
    .delete("/admin/tarif/removeAll")
    .then((response) => {
      console.log(response)
      return response
    })
    
}

const deleteTarif = (tarifId) => {
  return http
    .delete(`/admin/tarif/${tarifId}`)
    .then((response) => {
      console.log(response)
      return response
    })
    
}

const updateTarif = (data, tarifId) => {
  console.log(data);
  return http
    .put(`/admin/tarif/update/${tarifId}`, data)
    .then((response) => {
      return response.data
    })
}

const getTarif = (tarifId ) => {
  return http
    .get(`/admin/tarif/getOne/${tarifId}`)
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

const getTarifsBySize = (sizeId ) => {
  return http
    .get(`/admin/tarif/getTarifBySize/${sizeId}`)
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

export default {
  createTarif,
  listTarif,
  deleteAll,
  deleteTarif,
  updateTarif,
  getTarif,
  getTarifsBySize,
};
