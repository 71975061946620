import {
    SET_MESSAGE,
  } from "../message/type"
  import BoxService from "../../../services/box.service"
  import AdminService from "../../../services/admin.service"
import { CREATE_BOX_FAIL, CREATE_BOX_SUCCESS, DELETE_ALL_BOXESS_FAIL, DELETE_ALL_BOXESS_SUCCESS, DELETE_BOX_FAIL, DELETE_BOX_SUCCESS, LIST_BOXES_FAIL, LIST_BOXES_SUCCESS, SEARCH_BOX_DEPEND_ON_CABINE_FAIL, SEARCH_BOX_DEPEND_ON_CABINE_SUCCESS, SET_PRICES_FAIL, SET_PRICES_SUCCESS, UPDATE_BOX_FAIL, UPDATE_BOX_SUCCESS } from "./type"
  
  export const createBox = (data, cabineId) => (dispatch) => {
    return BoxService.createBox(data, cabineId).then(
      (response) => {
        console.log(response)
  
        dispatch({
          type: CREATE_BOX_SUCCESS,
          payload:  response.box
        })

        dispatch({
          type: SET_MESSAGE,
          payload:{
            message: response.message,
            status: response.status
          },
        });
  
        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        console.log(message)
        dispatch({
          type: CREATE_BOX_FAIL
        })
  console.log(error);
        dispatch({
          type: SET_MESSAGE,
          payload:{
            message: message,
            status: error.response.data.status
          },
        });
  
        return Promise.reject()
      }
    )
  }

  export const listBoxes = () => (dispatch) => {
    return BoxService.listBox().then(
      (response) => {
        console.log(response, 'ree');
        dispatch({
          type: LIST_BOXES_SUCCESS,
          payload: response.data   
        })
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.message
        })
  
        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        dispatch({
          type: LIST_BOXES_FAIL
        })
  
        dispatch({
          type: SET_MESSAGE,
          payload: message
        })
  
        return Promise.reject()
      }
    )
  }

  
export const removeBox = (id) => (dispatch) => {
  return BoxService.deleteBox(id).then(
    (response) => {
      console.log(response, 'delete');
      dispatch({
        type: DELETE_BOX_SUCCESS,
        payload: id
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.data
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: DELETE_BOX_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}


export const removeAllBoxes = () => (dispatch) => {
  return BoxService.deleteAll().then(
    (response) => {
      console.log(response, 'removeAll');
      dispatch({
        type: DELETE_ALL_BOXESS_SUCCESS,
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.data
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: DELETE_ALL_BOXESS_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}

export const editBox = (id, data) => (dispatch) => {
  return BoxService.update(id, data).then(
    (response) => {
      console.log(response, 'edit');
      dispatch({
        type: UPDATE_BOX_SUCCESS,
        payload: response.box
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.data
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: UPDATE_BOX_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}

export const listBoxesCabine = (cabineId) => (dispatch) => {
  return BoxService.listBoxOnCabine(cabineId).then(
    (response) => {
      console.log(response, 'ree');
      dispatch({
        type: SEARCH_BOX_DEPEND_ON_CABINE_SUCCESS,
        payload: response.data   
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.message
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: SEARCH_BOX_DEPEND_ON_CABINE_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}


export const setPrices = (data) => (dispatch) => {
  return AdminService.setPriceDependOnSize(data).then(
    (response) => {
      console.log(response, 'edit');
      dispatch({
        type: SET_PRICES_SUCCESS,
        payload: response.boxes
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.message
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: SET_PRICES_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}