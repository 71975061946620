import axios from "axios";

const access_token = null || JSON.parse(localStorage.getItem("access_token"));
console.log(process.env.REACT_APP_API_BASE_URL, '****************');
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json",
    Authorization: `${access_token}`,
  },
  withCredentials: false,
});
