export const CREATE_BOX_SUCCESS = "CREATE_BOX_SUCCESS";
export const CREATE_BOX_FAIL = "CREATE_BOX_FAIL";

export const LIST_BOXES_SUCCESS = "LIST_BOXES_SUCCESS";
export const LIST_BOXES_FAIL = "LIST_BOXES_FAIL";

export const DELETE_BOX_SUCCESS = "DELETE_BOX_SUCCESS"
export const DELETE_BOX_FAIL = "DELETE_BOX_FAIL"

export const DELETE_ALL_BOXESS_SUCCESS = "DELETE_ALL_BOXESS_SUCCESS"
export const DELETE_ALL_BOXESS_FAIL = "DELETE_ALL_BOXESS_FAIL"

export const UPDATE_BOX_SUCCESS = "UPDATE_BOX_SUCCESS"
export const UPDATE_BOX_FAIL = "UPDATE_BOX_FAIL"

export const SEARCH_BOX_DEPEND_ON_CABINE_SUCCESS = "SEARCH_BOX_DEPEND_ON_CABINE_SUCCESS"
export const SEARCH_BOX_DEPEND_ON_CABINE_FAIL = "SEARCH_BOX_DEPEND_ON_CABINE_FAIL"

export const SET_PRICES_SUCCESS = "SET_PRICES_SUCCESS"
export const SET_PRICES_FAIL = "SET_PRICES_FAIL"