import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL
  } from "./type"
  import {
    SET_MESSAGE,
  } from "../message/type"
  import AdminService from "../../../services/admin.service"
  
  export const login = (email, password) => (dispatch) => {
    return AdminService.signIn(email, password).then(
      (response) => {
        console.log(response)
        //localStorage.setItem("userData", JSON.stringify(data))
  
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response.user }
        })

        dispatch({
          type: SET_MESSAGE,
          payload:{
            message: response.message,
            status: response.status
          },
        });
  
        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        console.log(message)
        dispatch({
          type: LOGIN_FAIL
        })
  console.log(error);
        dispatch({
          type: SET_MESSAGE,
          payload:{
            message: message,
            status: error.response.data.status
          },
        });
  
        return Promise.reject()
      }
    )
  }

  export const logout = () => (dispatch) => {
    AdminService.logout();
  
    dispatch({
      type: LOGOUT,
    });
  };


  export const changePassword = (data) => (dispatch) => {
    console.log(data)
    return AdminService.changePassword(data).then(
      (response) => {
        console.log(response)
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS
        })
  
        dispatch({
          type: SET_MESSAGE,
          payload:{
            message: response.message,
            status: response.status
          },
        });
        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        dispatch({
          type: CHANGE_PASSWORD_FAIL
        })
        dispatch({
          type: SET_MESSAGE,
          payload:{
            message: message,
            status: error.response.data.status
          },
        });
  
        return Promise.reject()
      }
    )
  }

  export const updateProfile = (data) => (dispatch) => {
    console.log(data)
    return AdminService.updateProfile(data).then(
      (response) => {
        console.log(response)
        if (response.user) {
          dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload:{
              user: response?.user,
            },
          })
    
          dispatch({
            type: SET_MESSAGE,
            payload:{
              message: response.message,
              status: response.status
            },
          });
        } else {
          dispatch({
            type: UPDATE_PROFILE_FAIL
          })
          dispatch({
            type: SET_MESSAGE,
            payload:{
              message: response.message,
              status: response.status
            },
          });
        }

        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        dispatch({
          type: UPDATE_PROFILE_FAIL
        })
        dispatch({
          type: SET_MESSAGE,
          payload:{
            message: message,
            status: error.response.data.status
          },
        });
  
        return Promise.reject()
      }
    )
  }