import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useTable, useRowSelect, usePagination } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { list, remove, removeAll } from "redux/actions/cabines";

import ModalQR from "components/modals/ModalQR";
import CabineService from "services/cabine.service";
import AdminService from "services/admin.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const ListCabines = (props) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const { cabines } = useSelector((state) => state.cabines);
  const [cabineId, setCabineId] = useState('');
  const [qrcodeValue, setQrcodeValue] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    dispatch(list());
  }, []);

  const removeCabineById = (id) => {
    dispatch(remove(id));
  };

  const openEditCabine = (id) => {
    props.history.push(`/admin/cabine?id=${id}`);
  };

  const makeData = [
    {
      ref: "ref-1",
      name: "cabine 1",
      network_type: "wifi",
      mode: "etc",
    },
    {
      ref: "ref-2",
      name: "cabine 2",
      network_type: "wifi",
      mode: "etc2",
    },
    {
      ref: "ref-3",
      name: "cabine 3",
      network_type: "wifi",
      mode: "etc3",
    },
    {
      ref: "ref-4",
      name: "cabine 4",
      network_type: "wifi",
      mode: "etc 4",
    },
    {
      ref: "ref-5",
      name: "cabine 5",
      network_type: "wifi",
      mode: "etc-5",
    },
    {
      ref: "ref-6",
      name: "cabine 6",
      network_type: "wifi",
      mode: "etc-6",
    },
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: t('common.ref'),
        accessor: "ref",
      },
      {
        Header: t('common.name'),
        accessor: "name",
      },
      {
        Header: t('common.type_network'),
        accessor: "network_type",
      },
      {
        Header: t('common.mode'),
        accessor: "mode",
      },
      {
        Header: t('common.action'),
        accessor: "id",
        Cell: (props) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => openEditCabine(props.value)}>
                  Modifier
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  onClick={() => {
                    removeCabineById(props.value);
                  }}
                >
                  Supprimer
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={() => {
                    console.log(props.value);
                    setCabineId(props.value);
                    CabineService.getCabine(props.value)
                    .then( (response) => { console.log(response);
                       setQrcodeValue(response?.data?.shortLink) },
                    (error) => { console.log(error) }
                    )     }}
                >
                  Générer le code qr
                </DropdownItem>
                <DropdownItem
                onClick={() => {
                  console.log(props.value);
                  setCabineId(props.value);
                  AdminService.openAllAvailableBoxes(props.value)
                  AdminService.openAllAvailableBoxes(props.value)
                  .then((response) => { 
                    toast.success("les casiers disponibles sont ouvertes")
                    console.log(response);
                    },
                  (error) => { console.log(error); toast.error(error) }
                  )
                  .catch(err => {console.log(err); toast.error(err)})    
                }}
                >
                  Ouvrire les casiers
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  const data = React.useMemo(() => cabines, [cabines]);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            onClick={() => {
              console.log("selectedRowIds:", selectedRowIds);
            }}
          />
        </>
      );
    }
  );

  const getVisiblePages = (page, total) => {
    if (total < 5) {
      return [1, total];
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, total];
      }
    }
  };

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            // to get ID of row
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = tableInstance;

  const notify = () => toast.success("Lorem ipsum dolor")

  return (
    <>
      <Header />
      {/* Page content */}

      <Card className="shadow">
        <CardHeader className="border-0">
          <h3 className="mb-0"> {t('common.cabine_table_title')} </h3>
        </CardHeader>
        <Table
          {...getTableProps()}
          id="borderless-cell"
          className="align-items-center table-flush"
          responsive
        >
          <thead className="thead-light">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>

        {/* <div className="pagnation">
        <div style={{ display: "inline-block" }}>
          <button
 
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
        </div>
        <div className="buttonP">
          <button

            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <i className="fa fa-chevron-left"></i>
          </button>
        </div> */}
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={`${!canPreviousPage ? "disabled" : null}`}>
            <PaginationLink
              href="#pablo"
              onClick={() => {
                previousPage();
                console.log(page);
                console.log(canPreviousPage);
              }}
              // disabled={!canPreviousPage}
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>

          {getVisiblePages(pageIndex, pageCount).map((page, index, array) => {
            return (
              <PaginationItem
                key={index}
                className={`${pageIndex + 1 === page ? "active" : null}`}
              >
                <PaginationLink
                  href="#pablo"
                  onClick={() => {
                    gotoPage(page - 1);
                    console.log(page - 1);
                  }}
                  key={page}
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          <PaginationItem className={`${!canNextPage ? "disabled" : null}`}>
            <PaginationLink href="#pablo" onClick={() => nextPage()}>
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
        {/* {getVisiblePages(pageIndex, pageCount).map((page, index, array) => {
          return (
            <button

              onClick={() => {
                gotoPage(page - 1);
                console.log(page - 1);
              }}
              key={page}
            >
              {page}
            </button>
          );
        })}
        <div className="buttonP">
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>
        <div style={{ display: "inline-block" }}>
          <button

            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
        <span>
          {" "}
          | Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
        className="select-pagination"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 15].map((pageSize) => (
            <option style={{textAlignLast: 'center'}} key={pageSize} value={pageSize}>
               {pageSize}
            </option>
          ))}
        </select>
      </div> */}
      <ToastContainer />
      </Card>
      <ModalQR qrcodeValue={qrcodeValue} cabineId={cabineId} /> 
    </>
  );
};

export default ListCabines;
