import React, { useEffect, Fragment } from "react";
import { Router, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import isLoggedIn from "./helpers/isLoggedIn";
import { useDispatch, useSelector } from "react-redux";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/argon-dashboard-react.css";

import { history } from "./helpers/history";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

function App() {

  return (
    <Router history={history}>
      <Fragment>
        <BrowserRouter>
          <Switch>
            {isLoggedIn() ? <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> : <Route path="/auth" render={(props) => <AuthLayout {...props} />} />}
            {isLoggedIn() ? <Redirect from="/" to="/admin/index" /> : <Redirect from="/" to="/auth/login" />}
          </Switch>
        </BrowserRouter>
      </Fragment>
    </Router>
  );
}

export default App;
