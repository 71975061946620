import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { list, create, edit } from "redux/actions/cabines";
import Header from "components/Headers/Header";
import {useTranslation} from "react-i18next";


const AddCabine = () => {
  const { i18n, t } = useTranslation();
  const { message, status } = useSelector((state) => state.message);
  let { cabines } = useSelector((state) => state.cabines);
  
  const [ref, setRef] = useState("");
  const [name, setName] = useState("");
  const [networkType, setNetworkType] = useState("");
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);
  

  const dispatch = useDispatch();

  var url = window.location.href;
  var idCabine = url.substring(url.lastIndexOf('=') + 1);
  const initialCabineState = cabines?.filter(({ id }) => id === parseInt(idCabine))[0];

  const [cabine, setCabine] = useState(initialCabineState);

  useEffect(() => {
    dispatch(list())
    console.log(initialCabineState);
    console.log(cabines);
},[])


  const createCabine = () => {
    const data = {
      ref,
      name,
      network_type: 'WIFI',
      mode
    }

    setLoading(true)
    console.log(cabine);
    console.log(data);
      dispatch(create(cabine))
      .then(() => {
        setLoading(false)

      })
      .catch(() => {
        console.log('***');
        setLoading(false);
      });

    setTimeout(() => {setLoading(false)}, 5000);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCabine({ ...cabine, [name]: value });   
};

  const updateCabine = () => {
    const data = {
      ref,
      name,
      network_type: 'WIFI',
      mode
    }

    setLoading(true)
    console.log(cabine);
    console.log(data);
      dispatch(edit(idCabine, cabine))
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        console.log('***');
        setLoading(false);
      });

    setTimeout(() => {setLoading(false)}, 5000);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  {initialCabineState ? 
                    <Col xs="8">
                       <h3 className="mb-0"> {t('common.edit_cabine')} </h3>
                    </Col>
                  :
                    <Col xs="8">
                    <h3 className="mb-0"> {t('common.add_cabine')} </h3>
                    </Col>
                  }
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-ref"
                          >
                            Ref
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-ref"
                            placeholder="Réference"
                            name="ref"
                            type="text"
                            value={cabine?.ref}
                            onChange={(e)=>handleInputChange(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            {t('common.name')}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="Nom de cabine"
                            name="name"
                            type="text"
                            value={cabine?.name}
                            onChange={(e)=>handleInputChange(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />  
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="network-type"
                          >
                            {t('common.type_network')}
                          </label>
                          <select className="form-control form-control-alternative" id="exampleFormControlSelect1">
                            <option value={'WIFI'}>Wifi</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="mode"
                          >
                            Mode
                          </label>
                          <select className="form-control form-control-alternative" id="exampleFormControlSelect1" name="mode" value={cabine?.mode} onChange={(e)=>handleInputChange(e)} >
                            <option value={'CONFIG'}> {t('common.congig')} </option>
                            <option value={'RUNNING'}> {t('common.running')} </option>
                            <option value={'EMERGENCY'}> {t('common.emergency')} </option>
                            <option value={'OUT_OF_SERVICE'}> {t('common.out_of_service')} </option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    {message && (
                      <div className="form-group mt-4">
                        <div  className={`alert ${status==='error' ? "alert-danger" : "alert-success"}`}  role="alert">
                          {message}
                        </div>
                      </div>
                    )}
                    <Col className="text-right">
                      {initialCabineState ?
                         <Button
                         color="primary"
                         href="#pablo"
                         size="sm"
                         onClick={updateCabine}
                       >
                         {t('common.save')}
                         {loading && (
                           <span className="spinner-border spinner-border-sm"></span>
                         )}
                       </Button>
                  :
                  <Button
                  color="primary"
                  href="#pablo"
                  size="sm"
                  onClick={createCabine}
                >
                  {t('common.create')}
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                </Button>
                  }
                   <pre >
              {JSON.stringify(initialCabineState) }
            </pre>
                    </Col>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCabine;
