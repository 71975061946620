/*eslint-disable*/
import { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import { Button } from "reactstrap";
import {useTranslation} from "react-i18next";

const ModalQR = (props) => {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    console.log("________", props);
  }, []);

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `Cabine_${props.cabineId}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t('common.qr_code_cabine')} {props.cabineId}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body d-flex  align-items-center flex-column">
                <QRCode
                  id="qr-gen"
                  value={props.qrcodeValue}
                  size={290}
                  level={"H"}
                  includeMargin={true}
                />
                <Button color="primary" size="sm" onClick={downloadQRCode}>
                {t('common.download_qr_code')}
                </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalQR;
