import React, { useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, updateProfile } from "redux/actions/auth";
import {useTranslation} from "react-i18next";

const Profile = () => {
  const { i18n, t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { message, status } = useSelector((state) => state.message);

  const [email, setEmail] = useState(user?.email);
  const [username, setUsername] = useState(user?.username);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);

  const [code, setCode] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const dispatch = useDispatch();

  const validateEmail = (emailAdress) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    return !!emailAdress.match(regexEmail);
  };

  // const onChangeEmail = (e) => {
  //   const email = e.target.value;
  //   setEmail(email);
  // };

  // const onChangePassword = (e) => {
  //   const password = e.target.value;
  //   setPassword(password);
  // };

  const updatePro = () => {
    const data = {
      email,
      username,
    }

    setLoading(true)
    console.log(loading);
    dispatch(updateProfile(data))
    .then(() => {
      setLoading(false)
    })
    .catch(() => {
      console.log('***');
      setLoading(false);
    });
    setTimeout(() => {setLoading(false)}, 5000);
  };


  const CodeValidation = event => {
    const { name, value } = event.target;
    console.log(value);
    if(value.length !== 3){
      setErrorCode('le code doit comporter 3 chiffres')
    } 
    else{
      setErrorCode('')
    }
  };

  const updateCode = () => {
    const data = {
      code,
    }

    console.log(loading);
    if(errorCode === '') {
      setLoadingCode(true)
      dispatch(updateProfile(data))
      .then(() => {
        setLoadingCode(false)
      })
      .catch(() => {
        console.log('***');
        setLoadingCode(false);
      });
      setTimeout(() => {setLoadingCode(false)}, 5000);
    }
  };

  const updatePassword = () => {
    const data = {
      oldPassword,
      newPassword,
      confirmNewPassword
    }

    setLoading(true)
    console.log(loading);
    if(oldPassword.length !== 0 && oldPassword.length > 3 && newPassword.length !== 0 && newPassword.length > 3 && confirmNewPassword.length !== 0 && confirmNewPassword.length > 3) {
      dispatch(changePassword(data))
      .then(() => {
        setLoading(false)
        setOldPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
      })
      .catch(() => {
        console.log('***');
        setLoading(false);
      });
    }
    setTimeout(() => {setLoading(false)}, 5000);
  };
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0"> {t('common.my_account_title')} </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    {t('common.user_information_title')}
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {t('common.user_name')}
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.username}
                            onChange={(e)=> setUsername(e.target.value)}
                            id="input-username"
                            placeholder="Nom d'utilisateur"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            {t('common.email_address')}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={user.email}
                            onChange={(e)=> setEmail(e.target.value)}
                            placeholder="jesse@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Col className="text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={updatePro}
                        size="sm"
                      >
                        {t('common.save')}
                      </Button>
                    </Col>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    {t('common.security_information')}
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="new-password"
                          >
                            {t('common.new_password')}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="new-password"
                            placeholder="Entrez votre nouveau mot de passe"
                            type="password"
                            onChange={(e)=> setNewPassword(e.target.value)}
                          />
                          {newPassword.length < 5 && newPassword.length > 0 && loading ?
                            (<div className="error-input mt-2 ml-2"> {t('common.error_message_validation_password')} </div>) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="confirm-new-password"
                          >
                            {t('common.confirm_new_password')}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="confirm-new-password"
                            placeholder="confirmer le nouveau mot de passe"
                            type="password"
                            onChange={(e)=> setConfirmNewPassword(e.target.value)}
                          />
                          {confirmNewPassword.length < 5 && confirmNewPassword.length > 0 && loading ?
                            (<div className="error-input mt-2 ml-2"> {t('common.error_message_validation_password')} </div>) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            {t('common.current_password')}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="old-password"
                            placeholder="Entrer votre mot de passe actuel"
                            type="password"
                            onChange={(e)=> setOldPassword(e.target.value)}
                          />
                          {oldPassword.length < 5 && oldPassword.length > 0 && loading ?
                            (<div className="error-input mt-2 ml-2"> {t('common.error_message_validation_password')} </div>) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Col className="text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={updatePassword}
                        size="sm"
                      >
                        {t('common.save')}
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                      </Button>
                    </Col>
                  </div>
                  <hr className="my-4" />
                  <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="secret-code"
                          >
                            {t('common.secret_code')}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="secret-code"
                            placeholder="Entrez votre nouveau mot de passe"
                            type="number"
                            min="0"
                            defaultValue={user.code}
                            onChange={(e)=> {setCode(e.target.value); CodeValidation(e)}}
                          />
                          {
                          errorCode !=='' ?
                            <div className="error-input mt-2 ml-2">
                              {errorCode}
                            </div>
                          : null
                        }
                        </FormGroup>
                      </Col>
                    </Row>
                    <Col className="text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={updateCode}
                        size="sm"
                      >
                        {t('common.save')}
                        {loadingCode && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                      </Button>
                    </Col>

                    {message && (
                      <div className="form-group mt-4">
                        <div  className={`alert ${status==='error' ? "alert-danger" : "alert-success"}`}  role="alert">
                          {message}
                        </div>
                      </div>
                    )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
