import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  Form,
  FormGroup,
  Button,
  CardBody,
  Input,
  Label,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useTable, useRowSelect, usePagination } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { list, create, remove, tarifById, edit } from "redux/actions/tarifs";
import TarifService from "../../services/tarif.service";
import {useTranslation} from "react-i18next";

const Tarif = (props) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const { tarifs } = useSelector((state) => state.tarifs);
  const [tarifId, setTarifId] = useState(0);
  const initialTarifState = {
    duration: '',
    price: '',
    sizeId: 1,
    display: "1",
  };

  const [currentTarif, setCurrentTarif] = useState(null);
  var listTarifs;
  const [tarif, setTarif] = useState(initialTarifState);
  const [loading, setLoading] = useState(false);

  const removeCabineById = (id) => {
    console.log(id);
    dispatch(remove(id));
  };

  useEffect(() => {
    dispatch(list());
    TarifService.listTarif()
    .then((res) => {
      console.log("--------",res.data);
      listTarifs = res.data
      console.log(listTarifs);
    })
    .catch(() => {
      console.log("***");
    });

  }, [dispatch]);

  const openEditTarif = (idTarif) => {
    console.log(listTarifs);
    setCurrentTarif(listTarifs?.filter(({ id }) => id === idTarif)[0])
      dispatch(tarifById(idTarif));
    setTarifId(idTarif);
    // setTarif(tarifs?.filter(({ id }) => id === idTarif)[0])
    console.log(tarifs);
    // props.history.push(`/admin/cabine?id=${id}`);
  };

  const createCabine = () => {
    console.log(parseInt(tarif.sizeId));
    tarif.sizeId = parseInt(tarif.sizeId);
    console.log(data);
    tarif.display = parseInt(tarif.display);
    console.log(tarif);
    dispatch(create(tarif))
      .then(() => {
        setLoading(false);
        setTarif(initialTarifState)
      })
      .catch(() => {
        console.log("***");
        setLoading(false);
      });
  };

  const updateCabine = () => {
    console.log(currentTarif, tarifId);
    dispatch(edit(currentTarif, tarifId))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        console.log("***");
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, "---", tarif?.display);
    setTarif({ ...tarif, [name]: value });
    console.log(name, "---", tarif?.display);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setCurrentTarif(prevState => ({
        ...prevState,
        [name]: value
    }));
};

  const columns = React.useMemo(
    () => [
      {
        Header: t('common.my_account_title'),
        accessor: "duration",
        Cell: (props) => {
          return <span>{props.value} </span>;
        },
      },
      {
        Header: t('common.price'),
        accessor: "price",
        Cell: (props) => {
          return <span>{props.value} €</span>;
        },
      },
      {
        Header: t('common.size'),
        accessor: "Size",
        Cell: (props) => {
          return <span>{props.value?.name} </span>;
        },
      },
      {
        Header: t('common.display_tarif_to_client'),
        accessor: "display",
        Cell: (props) => {
          console.log(props.value);
          return (
            <>{props.value ? <span> {t('common.display')} </span> : <span> {t('common.hide')} </span>} </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: (props) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => openEditTarif(props.value)}>
                  Modifier
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  onClick={() => {
                    removeCabineById(props.value);
                  }}
                >
                  Supprimer
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  const data = React.useMemo(() => tarifs, [tarifs]);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            onClick={() => {
              console.log("selectedRowIds:", selectedRowIds);
            }}
          />
        </>
      );
    }
  );

  const getVisiblePages = (page, total) => {
    if (total < 5) {
      return [1, total];
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, total];
      }
    }
  };

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            // to get ID of row
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = tableInstance;

  return (
    <>
      <Header />
      {/* Page content */}
      <Row>
        <Col className="order-xl-1" xl="3" style={{ paddingRight: 0 }}>
          {!tarifId ? (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0"> {t('common.add_price')} </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ marginBottom: "36px" }}>
                <Form>
                  <div className="">
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-duration"
                        >
                          Durée (heure)
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-duration"
                          placeholder="Heure"
                          name="duration"
                          type="text"
                          value={tarif?.duration} 
                          onChange={(e) => handleInputChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-price"
                        >
                          {t('common.price')} (€)
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-name"
                          placeholder=" €"
                          name="price"
                          type="text"
                          value={tarif?.price} 
                          onChange={(e) => handleInputChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="network-type"
                        >
                          {t('common.size')}
                        </label>
                        <select
                          className="form-control form-control-alternative"
                          id="exampleFormControlSelect1"
                          name="sizeId"
                          value={tarif?.sizeId} 
                          onChange={(e) => handleInputChange(e)}
                        >
                          <option value={1}>{t('common.small')}</option>
                          <option value={2}>{t('common.basic')}</option>
                          <option value={3}>{t('common.family')}</option>
                          <option value={4}>{t('common.surf')}</option>
                        </select>
                      </FormGroup>
                    </Col>

                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="display"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          checked={tarif?.display === "1"}
                          value={"1"}
                        />{" "}
                        {t('common.display_tarif_to_client')}
                      </Label>
                      <br />
                      <Label check>
                        <Input
                          type="radio"
                          name="display"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          checked={tarif?.display === "0"}
                          value={"0"}
                        />{" "}
                        {t('common.hide_tarif_to_client')}
                      </Label>
                    </FormGroup>
                  </div>

                  <Col className="text-right">
                    <Button color="primary" size="sm" onClick={createCabine}>
                      Créer
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          ) : (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Modifier Tarif</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ marginBottom: "36px" }}>
                <Form>
                  <div className="">
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-duration"
                        >
                          Durée (heure)
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-duration"
                          placeholder="Heure"
                          name="duration"
                          type="text"
                          value={currentTarif?.duration}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-price"
                        >
                          {t('common.price')} (€)
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-name"
                          placeholder=" €"
                          name="price"
                          type="text"
                          value={currentTarif?.price}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="network-type"
                        >
                          Taille
                        </label>
                        <select
                          className="form-control form-control-alternative"
                          id="exampleFormControlSelect1"
                          name="sizeId"
                          value={currentTarif?.sizeId}
                          onChange={handleChange}
                        >
                          <option value={1}>{t('common.small')}</option>
                          <option value={2}>{t('common.basic')}</option>
                          <option value={3}>{t('common.family')}</option>
                          <option value={4}>{t('common.surf')}</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </div>

                 <Row className="justify-content-around ml-2">
                 <Col>
                    <Button color="primary" size="sm" onClick={updateCabine}>
                    {t('common.save')}
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                  </Col>
                  <Col >
                    <Button color="primary" size="sm" onClick={()=>{ setTarifId(null);setTarif({duration: '',
price: '',
    sizeId: 1,
    display: "1"});console.log(initialTarifState);console.log(tarif);}}>
                      nouveau
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                  </Col>
                 </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Col>
        <Col className="order-xl-1" xl="9" style={{ paddingLeft: 0 }}>
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0"> {t('common.tarif_table_title')} </h3>
            </CardHeader>
            <Table
              {...getTableProps()}
              id="borderless-cell"
              className="align-items-center table-flush"
              responsive
            >
              <thead className="thead-light">
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th {...column.getHeaderProps()}>
                            {
                              // Render the header
                              column.render("Header")
                            }
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              {/* Apply the table body props */}
              <tbody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map((row) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props
                            return (
                              <td {...cell.getCellProps()}>
                                {
                                  // Render the cell contents
                                  cell.render("Cell")
                                }
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>

            {/* <div className="pagnation">
        <div style={{ display: "inline-block" }}>
          <button
 
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
        </div>
        <div className="buttonP">
          <button

            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <i className="fa fa-chevron-left"></i>
          </button>
        </div> */}
            <Pagination
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem
                className={`${!canPreviousPage ? "disabled" : null}`}
              >
                <PaginationLink
                  href="#pablo"
                  onClick={() => {
                    previousPage();
                    console.log(page);
                    console.log(canPreviousPage);
                  }}
                  // disabled={!canPreviousPage}
                  tabIndex="-1"
                >
                  <i className="fas fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>

              {getVisiblePages(pageIndex, pageCount).map(
                (page, index, array) => {
                  return (
                    <PaginationItem
                      key={index}
                      className={`${pageIndex + 1 === page ? "active" : null}`}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={() => {
                          gotoPage(page - 1);
                          console.log(page - 1);
                        }}
                        key={page}
                      >
                        {page}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }
              )}
              <PaginationItem className={`${!canNextPage ? "disabled" : null}`}>
                <PaginationLink href="#pablo" onClick={() => nextPage()}>
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
            {/* {getVisiblePages(pageIndex, pageCount).map((page, index, array) => {
          return (
            <button

              onClick={() => {
                gotoPage(page - 1);
                console.log(page - 1);
              }}
              key={page}
            >
              {page}
            </button>
          );
        })}
        <div className="buttonP">
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>
        <div style={{ display: "inline-block" }}>
          <button

            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
        <span>
          {" "}
          | Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
        className="select-pagination"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 15].map((pageSize) => (
            <option style={{textAlignLast: 'center'}} key={pageSize} value={pageSize}>
               {pageSize}
            </option>
          ))}
        </select>
      </div> */}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Tarif;
