
import { CREATE_CABINE_FAIL, CREATE_CABINE_SUCCESS, DELETE_ALL_CABINES_FAIL, DELETE_ALL_CABINES_SUCCESS, DELETE_CABINE_FAIL, DELETE_CABINE_SUCCESS, LIST_CABINE_FAIL, LIST_CABINE_SUCCESS, UPDATE_CABINE_FAIL, UPDATE_CABINE_SUCCESS } from "./type"
import {
  SET_MESSAGE,
} from "../message/type"
import CabineService from "../../../services/cabine.service"

export const create = (data) => (dispatch) => {
  return CabineService.createCabine(data).then(
    (response) => {
      console.log(response)

      dispatch({
        type: CREATE_CABINE_SUCCESS,
        payload:  response.cabine
      })

      dispatch({
        type: SET_MESSAGE,
        payload:{
          message: response.message,
          status: response.status
        },
      });

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      console.log(message)
      dispatch({
        type: CREATE_CABINE_FAIL
      })
console.log(error);
      dispatch({
        type: SET_MESSAGE,
        payload:{
          message: message,
          status: error.response.data.status
        },
      });

      return Promise.reject()
    }
  )
}

export const list = () => (dispatch) => {
  return CabineService.listCabine().then(
    (response) => {
      console.log(response, 'ree');
      dispatch({
        type: LIST_CABINE_SUCCESS,
        payload: response.data   
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.message
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: LIST_CABINE_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}


export const remove = (id) => (dispatch) => {
return CabineService.deleteCabine(id).then(
  (response) => {
    console.log(response, 'delete');
    dispatch({
      type: DELETE_CABINE_SUCCESS,
      payload: id
    })

    dispatch({
      type: SET_MESSAGE,
      payload: response.data
    })

    return Promise.resolve()
  },
  (error) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString()

    dispatch({
      type: DELETE_CABINE_FAIL
    })

    dispatch({
      type: SET_MESSAGE,
      payload: message
    })

    return Promise.reject()
  }
)
}


export const removeAll = () => (dispatch) => {
return CabineService.deleteAll().then(
  (response) => {
    console.log(response, 'removeAll');
    dispatch({
      type: DELETE_ALL_CABINES_SUCCESS,
    })

    dispatch({
      type: SET_MESSAGE,
      payload: response.data
    })

    return Promise.resolve()
  },
  (error) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString()

    dispatch({
      type: DELETE_ALL_CABINES_FAIL
    })

    dispatch({
      type: SET_MESSAGE,
      payload: message
    })

    return Promise.reject()
  }
)
}

export const edit = (id, data) => (dispatch) => {
return CabineService.update(id, data).then(
  (response) => {
    console.log(response, 'edit');
    dispatch({
      type: UPDATE_CABINE_SUCCESS,
      payload: response.cabine
    })

    dispatch({
      type: SET_MESSAGE,
      payload: response.data
    })

    return Promise.resolve()
  },
  (error) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString()

    dispatch({
      type: UPDATE_CABINE_FAIL
    })

    dispatch({
      type: SET_MESSAGE,
      payload: message
    })

    return Promise.reject()
  }
)
}