import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  CardBody,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useTable, useRowSelect, usePagination } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { listBoxes, removeBox, listBoxesCabine, setPrices } from "redux/actions/boxes";
import { list } from "redux/actions/cabines";
import {useTranslation} from "react-i18next";

const ListBox = (props) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const { boxes } = useSelector((state) => state.boxes);
  const { cabines } = useSelector((state) => state.cabines);
  const [cabineId, setCabineId] = useState(0);

  const [price, setPrice] = useState(null);
  const [size, setSize] = useState('SMALL');

  useEffect(() => {
    dispatch(listBoxes());
    dispatch(list());
    console.log(boxes, '*-*-*-*-*');
  }, []);

  const removeBoxById = (id) => {
    dispatch(removeBox(id));
  };

  const openEditBox = (id) => {
    props.history.push(`/admin/box?id=${id}`);
  };

  const setNewPrices = () => {
    const data = {
      size,
      price
    }
    dispatch(setPrices(data));
  };

  const makeData = [
    {
      ref: "ref-1",
      name: "cabine 1",
      network_type: "wifi",
      mode: "etc",
    },
    {
      ref: "ref-2",
      name: "cabine 2",
      network_type: "wifi",
      mode: "etc2",
    },
    {
      ref: "ref-3",
      name: "cabine 3",
      network_type: "wifi",
      mode: "etc3",
    },
    {
      ref: "ref-4",
      name: "cabine 4",
      network_type: "wifi",
      mode: "etc 4",
    },
    {
      ref: "ref-5",
      name: "cabine 5",
      network_type: "wifi",
      mode: "etc-5",
    },
    {
      ref: "ref-6",
      name: "cabine 6",
      network_type: "wifi",
      mode: "etc-6",
    },
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: t('common.ref'),
        accessor: "ref",
      },
      {
        Header: t('common.number'),
        accessor: "number",
      },
      {
        Header: t('common.price'),
        accessor: "price",
      },
      {
        Header: t('common.size'),
        accessor: "sizeId",
        Cell: (props) => {
          console.log(props.value);
          if (props.value === 1) {
            return (
              <Badge color="primary"> {t('common.small')} </Badge>
            );
          } else if (props.value === 2) {
            return (
              <Badge color="primary"> {t('common.basic')} </Badge>
            );
          } else if (props.value === 3) {
            return (
              <Badge color="primary"> {t('common.family')} </Badge>
            );
          } else if (props.value === 4) {
            return (
              <Badge color="primary"> {t('common.surf')} </Badge>
            );
          }
          
        },
      },
      {
        Header: t('common.status'),
        accessor: "status",
      },
      {
        Header: t('common.board_id'),
        accessor: "boardId",
      },
      {
        Header: t('common.door_number'),
        accessor: "doorNumber",
      },
      {
        Header: t('common.availability'),
        accessor: "availibility",
        Cell: (props) => {
          console.log(props.value);
          return (
<div>
{
  props.value ? 
  <Badge color="success"> Disponible </Badge>
  : 
  <Badge color="danger"> Indisponible </Badge>
}
</div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: (props) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => openEditBox(props.value)}>
                  Modifier
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  onClick={() => {
                    removeBoxById(props.value);
                  }}
                >
                  Supprimer
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  const data = React.useMemo(() => boxes, [boxes]);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            onClick={() => {
              console.log("selectedRowIds:", selectedRowIds);
            }}
          />
        </>
      );
    }
  );

  const getVisiblePages = (page, total) => {
    if (total < 5) {
      return [1, total];
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, total];
      }
    }
  };

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            // to get ID of row
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = tableInstance;


  const onChangeSearchBoxes = (e) => {
    console.log(e.target.value === 'null');
    // setSearchCustomer(cabineId);
    if(e.target.value === 'null'){
      dispatch(listBoxes());
    } else {
      dispatch(listBoxesCabine(e.target.value)) 
    }

  };
  return (
    <>
      <Header />
      {/* Page content */}
      {/* <Row>
      <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="mb-0">Définissez le prix de vos casier</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
              <Row className="d-flex align-content-center">
                      <Col lg="5">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-price"
                          >
                            Prix
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-price"
                            placeholder="Prix"
                            name="price"
                            type="number"
                            onChange={(e)=> setPrice(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="5">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="network-type"
                          >
                            Taille
                          </label>
                          <select className="form-control form-control-alternative" name="size" value={size}
                            onChange={(e)=> setSize(e.target.value)}
                            >
                            <option value={'SMALL'}>Petit</option>
                            <option value={'BASIC'}>Petit</option>
                            <option value={'FAMILY'}>Familiale</option>
                            <option value={'SURF'}>Surf</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="2" style={{display:'flex', alignItems:'center'}}>
                      <Button
                         color="primary"
                         href="#pablo"
                         size="sm"
                         onClick={setNewPrices}
                       >
                         Save
                       </Button>
                      </Col>
                    </Row>
              </CardBody>
            </Card>
            </Col>
          </Row> */}
          <Row>
          <Col>
          <Card className="shadow">
        <CardHeader className="border-0">
          <h3 className="mb-0">Tableau de casier</h3>
          <select
            className="form-control form-control-alternative"
            name="cabineId"
            value={cabineId}
            onChange={onChangeSearchBoxes}
          >
            <option value={'null'}>Chercher les casier dependent au cabine</option>
            {cabines.map((cab) => (
              <option key={cab.id} value={cab.id}>
                {cab.name}
              </option>
            ))}
            ;
          </select>
        </CardHeader>
        <Table
          {...getTableProps()}
          id="borderless-cell"
          className="align-items-center table-flush"
          responsive
        >
          <thead className="thead-light">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>

        {/* <div className="pagnation">
        <div style={{ display: "inline-block" }}>
          <button
 
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
        </div>
        <div className="buttonP">
          <button

            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <i className="fa fa-chevron-left"></i>
          </button>
        </div> */}
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={`${!canPreviousPage ? "disabled" : null}`}>
            <PaginationLink
              href="#pablo"
              onClick={() => {
                previousPage();
                console.log(page);
                console.log(canPreviousPage);
              }}
              // disabled={!canPreviousPage}
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>

          {getVisiblePages(pageIndex, pageCount).map((page, index, array) => {
            return (
              <PaginationItem
                key={index}
                className={`${pageIndex + 1 === page ? "active" : null}`}
              >
                <PaginationLink
                  href="#pablo"
                  onClick={() => {
                    gotoPage(page - 1);
                    console.log(page - 1);
                  }}
                  key={page}
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          <PaginationItem className={`${!canNextPage ? "disabled" : null}`}>
            <PaginationLink href="#pablo" onClick={() => nextPage()}>
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
        {/* {getVisiblePages(pageIndex, pageCount).map((page, index, array) => {
          return (
            <button

              onClick={() => {
                gotoPage(page - 1);
                console.log(page - 1);
              }}
              key={page}
            >
              {page}
            </button>
          );
        })}
        <div className="buttonP">
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>
        <div style={{ display: "inline-block" }}>
          <button

            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
        <span>
          {" "}
          | Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
        className="select-pagination"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 15].map((pageSize) => (
            <option style={{textAlignLast: 'center'}} key={pageSize} value={pageSize}>
               {pageSize}
            </option>
          ))}
        </select>
      </div> */}
      </Card>
      </Col>
          </Row>
    </>
  );
};

export default ListBox;
