import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { listBox, createBox, editBox } from "redux/actions/boxes";
import { list } from "redux/actions/cabines";
import { listTarifsBySize, } from "redux/actions/tarifs";
import Header from "components/Headers/Header";


const Box = () => {
  const { message, status } = useSelector((state) => state.message);
  let { boxes } = useSelector((state) => state.boxes);
  const { cabines } = useSelector((state) => state.cabines);
  const { tarifs } = useSelector((state) => state.tarifs);

  const [name, setName] = useState("");
  const [networkType, setNetworkType] = useState("");
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorDoorNumber, setErrorDoorNumber] = useState('');
  const [errorBoardID, setErrorBoardID] = useState('');
  const history = useHistory();


  const dispatch = useDispatch();

  var url = window.location.href;
  var idbox = url.substring(url.lastIndexOf('=') + 1);
  const initialBoxState = boxes?.filter(({ id }) => id === parseInt(idbox))[0];
  const [cabineId, setCabineId] = useState(initialBoxState?.cabineId);

  const [box, setBox] = useState(initialBoxState);

  useEffect(() => {
    dispatch(list())
    dispatch(listTarifsBySize(1))
},[])


  const create = () => {


    // setLoading(true)
    console.log(parseInt(box.sizeId));
    const data ={
      status: box.status === undefined ? 'BROKEN' : box.status,
      boardId: box.boardId,
      doorNumber: box.doorNumber,
      availibility:box.availibility==="false" ? false : true,
      sizeId: box.sizeId === undefined ? 1 : (parseInt(box.sizeId)) ,
    }
    console.log(data);
    if(cabineId){
      dispatch(createBox(data, cabineId))
      .then(() => {
        setLoading(false)

      })
      .catch(() => {
        console.log('***');
        setLoading(false);
      });
    }

    setTimeout(() => {setLoading(false)}, 5000);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    console.log(name, value);
    setBox({ ...box, [name]: value });   
};

const doorNumberValidation = event => {
  const { name, value } = event.target;
  if(value < 1 || value > 24){
    setErrorDoorNumber('Le valeur de doorNumber doit etre entre 1 et 24')
  } 
  else{
    setErrorDoorNumber('')
  }
};

const boardIDValidation = event => {
  const { name, value } = event.target;
  if(value < 1 || value > 3){
    setErrorBoardID('Le valeur de doorNumber doit etre entre 1 et 3')
  } 
  else{
    setErrorBoardID('')
  }
};

  const updateBox = () => {

    setLoading(true)
    console.log(box);
      dispatch(editBox(idbox, box))
      .then(() => {
        setLoading(false)
        history.push("/admin/boxes")
      })
      .catch(() => {
        console.log('***');
        setLoading(false);
      });

    setTimeout(() => {setLoading(false)}, 5000);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  {initialBoxState ? (
                    <Col xs="8">
                      <h3 className="mb-0">Modifier Casier</h3>
                    </Col>
                  ) : (
                    <Col xs="8">
                      <h3 className="mb-0">Ajouter Box</h3>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="mode">
                            Sur quelle cabine votre casier sera installée
                          </label>
                          <select
                            className="form-control form-control-alternative"
                            name="cabineId"
                            value={cabineId}
                            onChange={(e) => setCabineId(e.target.value)}
                          >
                            <option value={null}>
                              choisissez votre cabine
                            </option>
                            {cabines.map((cab) => (
                              <option key={cab.id} value={cab.id}>
                                {cab.name}
                              </option>
                            ))}
                            ;
                          </select>
                          {!cabineId && loading ? (
                            <div className="error-input mt-2 ml-2">
                              La cabine est obligatoire
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="network-type"
                          >
                            Taille
                          </label>
                          <select
                            className="form-control form-control-alternative"
                            name="sizeId"
                            value={box?.size}
                            onChange={(e) => {
                              handleInputChange(e);
                              dispatch(
                                listTarifsBySize(parseInt(e.target.value))
                              );
                            }}
                          >
                            <option value={1}>Petit</option>
                            <option value={2}>Basique</option>
                            <option value={3}>Familiale</option>
                            <option value={4}>Surf</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-4" />

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-ref"
                          >
                            Board ID
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-ref"
                            placeholder="1"
                            name="boardId"
                            type="text"
                            value={box?.boardId}
                            onChange={(e) => {handleInputChange(e); boardIDValidation(e);}}
                          />
                        </FormGroup>
                        {
                          errorBoardID !=='' && box?.boardId!=='' ?
                            <div className="error-input mt-2 ml-2">
                              {errorBoardID}
                            </div>
                          : null
                        }
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Door Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="2"
                            name="doorNumber"
                            type="text"
                            value={box?.doorNumber}
                            onChange={(e) => {handleInputChange(e); doorNumberValidation(e);}}
                          />
                        </FormGroup>
                        {
                          errorDoorNumber !=='' && box?.doorNumber!=='' ?
                            <div className="error-input">
                              {errorDoorNumber}
                            </div>
                          : null
                        }
                      </Col>
                    </Row>
                    <hr className="my-4" />

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="mode">
                            Status
                          </label>
                          <select
                            className="form-control form-control-alternative"
                            id="exampleFormControlSelect1"
                            name="status"
                            value={box?.status}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value={"BROKEN"}>Cassé</option>
                            <option value={"OUT_OF_SERVICE"}>
                              Hors service
                            </option>
                            <option value={"WORKING"}>Travaillant</option>
                          </select>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="mode">
                            Disponibilité
                          </label>
                          <select
                            className="form-control form-control-alternative"
                            id="exampleFormControlSelect1"
                            name="availibility"
                            value={box?.availibility}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value={true}>Disponible</option>
                            <option value={false}>Non Disponible</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    {/* <Row> */}

                    {/* <Col lg="6">
                        <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="network-type"
                            >
                            Tarifs
                          </label>
                          <select className="form-control form-control-alternative" name="sizeId" value={box?.size} onChange={(e)=>handleInputChange(e) } >
        
                            {tarifs.map(({ id, price }, index) => <option value={id} >{price}</option>)}
                          </select>
                        </FormGroup>
                      </Col> */}

                    {/* <table style={{border:'1px solid black'}}>
    <thead style={{border:'1px solid black'}}>
        <tr style={{border:'1px solid black'}}>
            <th colspan="2">Tarifs</th>
        </tr>
        <tr>
            <td style={{border:'1px solid black'}}>Duration</td>
            <td>Price</td>
        </tr>
    </thead>
    <tbody>
    {tarifs.map(({ id, duration, price }, index) => 
        <tr >
                   <td style={{border:'1px solid black'}}> {duration} </td>
          <td style={{border:'1px solid black'}}> {price} </td>
            
        </tr>
        )}
    </tbody>
</table> */}
                    {/* </Row> */}
                    {message && (
                      <div className="form-group mt-4">
                        <div
                          className={`alert ${
                            status === "error"
                              ? "alert-danger"
                              : "alert-success"
                          }`}
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                    <Col className="text-right">
                      {initialBoxState ? (
                        <Button
                          color="primary"
                          href="#pablo"
                          size="sm"
                          onClick={updateBox}
                        >
                          Sauvegarder
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          href="#pablo"
                          size="sm"
                          onClick={create}
                        >
                          Create
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                        </Button>
                      )}
                      {/* <pre>{JSON.stringify(initialBoxState)}</pre> */}
                    </Col>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Box;
