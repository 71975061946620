import React from 'react';
import ReactDOM from 'react-dom';

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/argon-dashboard-react.css";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import { Provider } from "react-redux";
import { store } from './store'
import App from './App';
import './i18n';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);