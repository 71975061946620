// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import {useTranslation} from "react-i18next";

const UserHeader = () => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "400px",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col md="12">
              <h1 className="display-2 text-white"> {t('common.user_profile')} </h1>
              <p className="text-white mt-0 mb-5">
                {t('common.profile_admin_sub_title')}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
