import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Cabines from "views/examples/ListCabines";
import AddCabine from "views/examples/AddCabine";
import Box from "views/examples/Box";
import Boxes from "views/examples/ListBox";
import Tarif from "views/examples/Tarif";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Profile d'Utilisateur",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/cabines",
    name: "Cabines",
    icon: "ni ni-collection text-orange",
    component: Cabines,
    layout: "/admin",
  },
  {
    path: "/cabine",
    name: "Ajouter Cabine",
    icon: "ni ni-fat-add text-green",
    component: AddCabine,
    layout: "/admin",
  },
  {
    path: "/boxes",
    name: "Boxes",
    icon: "ni ni-collection text-orange",
    component: Boxes,
    layout: "/admin",
  },
  {
    path: "/box",
    name: "Ajouter Box",
    icon: "ni ni-fat-add text-green",
    component: Box,
    layout: "/admin",
  },
  {
    path: "/tarif",
    name: "Tarif",
    icon: "ni ni-money-coins text-blue",
    component: Tarif,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
];
export default routes;
