import http from "./http-common";

const signIn = (email, password) => {
  return http
    .post("/admin/login", {
      email,
      password,
    })
    .then((response) => {
      console.log(response);
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem(
          "access_token",
          JSON.stringify(response.data.token)
        );
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};


const changePassword = (data) => {
  console.log(data);
  return http.put(`/admin/updatePassword`,data ).then((response) => {
    console.log(response);
    return response.data;
  });
};


const setPriceDependOnSize = (data) => {
  console.log(data);
  return http
    .put(`/admin/setPriceDependOnSize`, data)
    .then((response) => {
      return response.data
    })
}


const updateProfile = (data) => {
  console.log(data);
  return http.put(`/admin/updateProfile`,data ).then((response) => {
    localStorage.setItem("user", JSON.stringify(response.data.user));
    console.log(response);
    return response.data;
  });
};

const openAllAvailableBoxes = (cabineId) => {
  console.log(cabineId);
  return http.post(`/admin/openAllAvailableBoxes/${cabineId}` ).then((response) => {
    return response.data;
  });
};

export default {
  signIn,
  logout,
  changePassword,
  setPriceDependOnSize,
  updateProfile,
  openAllAvailableBoxes
};
