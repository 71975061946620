import { CREATE_BOX_SUCCESS } from "redux/actions/boxes/type";
import { DELETE_BOX_SUCCESS } from "redux/actions/boxes/type";
import { DELETE_ALL_BOXESS_SUCCESS } from "redux/actions/boxes/type";
import { UPDATE_BOX_SUCCESS } from "redux/actions/boxes/type";
import { SEARCH_BOX_DEPEND_ON_CABINE_FAIL } from "redux/actions/boxes/type";
import { SET_PRICES_SUCCESS } from "redux/actions/boxes/type";
import { SET_PRICES_FAIL } from "redux/actions/boxes/type";
import { SEARCH_BOX_DEPEND_ON_CABINE_SUCCESS } from "redux/actions/boxes/type";
import { UPDATE_BOX_FAIL } from "redux/actions/boxes/type";
import { DELETE_ALL_BOXESS_FAIL } from "redux/actions/boxes/type";
import { DELETE_BOX_FAIL } from "redux/actions/boxes/type";
import { CREATE_BOX_FAIL } from "redux/actions/boxes/type";
import { LIST_BOXES_FAIL } from "redux/actions/boxes/type";
import { LIST_BOXES_SUCCESS } from "redux/actions/boxes/type";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  boxes: [],
  box: null,
};

const boxReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_BOXES_SUCCESS:
      return {
        ...state,
        boxes: payload,
      };
    case LIST_BOXES_FAIL:
      return {
        ...state,
      };
    case CREATE_BOX_SUCCESS:
      return {
        ...state,
        boxes: [...state.boxes, payload],
      };
    case CREATE_BOX_FAIL:
      return {
        ...state,
      };
    case DELETE_BOX_SUCCESS:
      return {
        ...state,
        boxes: state.boxes.filter(({ id }) => id !== payload),
      };
    case DELETE_BOX_FAIL:
      return {
        ...state,
      };
    case DELETE_ALL_BOXESS_SUCCESS:
      return {
        ...state,
        boxes: [],
      };
    case DELETE_ALL_BOXESS_FAIL:
      return {
        ...state,
      };
    case UPDATE_BOX_SUCCESS:
      console.log(payload, "edit boxes paylaod", state.boxes);
      return {
        ...state,
        boxes: state.boxes.map((box) => {
          console.log(box.id === payload.id, "dddddddddddd");
          if (box.id === payload.id) {
            return {
              ...box,
              ...payload,
            };
          } else {
            return box;
          }
        }),
      };
    case UPDATE_BOX_FAIL:
      return {
        ...state,
      };
    case SEARCH_BOX_DEPEND_ON_CABINE_SUCCESS:
      console.log(payload);
      return {
        ...state,
        boxes: payload,
      };
    case SEARCH_BOX_DEPEND_ON_CABINE_FAIL:
      return {
        ...state,
      };
    case SET_PRICES_SUCCESS:
      return {
        ...state,
        boxes: payload,
      };
    case SET_PRICES_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default boxReducer;
