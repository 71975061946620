// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import cabines from "./cabines";
import boxes from "./boxes";
import tarifs from "./tarifs";
import message from "./message";

const rootReducer = combineReducers({
  auth,
  cabines,
  boxes,
  tarifs,
  message,
});

export default rootReducer;
