export const CREATE_CABINE_SUCCESS = "CREATE_CABINE_SUCCESS";
export const CREATE_CABINE_FAIL = "CREATE_CABINE_FAIL";

export const LIST_CABINE_SUCCESS = "LIST_CABINE_SUCCESS";
export const LIST_CABINE_FAIL = "LIST_CABINE_FAIL";

export const DELETE_CABINE_SUCCESS = "DELETE_CABINE_SUCCESS"
export const DELETE_CABINE_FAIL = "DELETE_CABINE_FAIL"

export const DELETE_ALL_CABINES_SUCCESS = "DELETE_ALL_CABINES_SUCCESS"
export const DELETE_ALL_CABINES_FAIL = "DELETE_ALL_CABINES_FAIL"

export const UPDATE_CABINE_SUCCESS = "UPDATE_CABINE_SUCCESS"
export const UPDATE_CABINE_FAIL = "UPDATE_CABINE_FAIL"