  import {
    SET_MESSAGE,
  } from "../message/type"
  import TarifService from "../../../services/tarif.service"
import { CREATE_TARIF_FAIL, CREATE_TARIF_SUCCESS, DELETE_ALL_TARIFS_FAIL, DELETE_ALL_TARIFS_SUCCESS, DELETE_TARIF_FAIL, DELETE_TARIF_SUCCESS, GET_TARIF_BY_ID_FAIL, GET_TARIF_BY_ID_SUCCESS, LIST_TARIF_FAIL, LIST_TARIF_SUCCESS, UPDATE_TARIF_FAIL, UPDATE_TARIF_SUCCESS } from "./type"
  
  export const create = (data) => (dispatch) => {
    return TarifService.createTarif(data).then(
      (response) => {
        console.log(response)
  
        dispatch({
          type: CREATE_TARIF_SUCCESS,
          payload:  response.tarif
        })

        dispatch({
          type: SET_MESSAGE,
          payload:{
            message: response.message,
            status: response.status
          },
        });
  
        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        console.log(message)
        dispatch({
          type: CREATE_TARIF_FAIL
        })
  console.log(error);
        dispatch({
          type: SET_MESSAGE,
          payload:{
            message: message,
            status: error.response.data.status
          },
        });
  
        return Promise.reject()
      }
    )
  }

  export const list = () => (dispatch) => {
    return TarifService.listTarif().then(
      (response) => {
        console.log(response, 'ree');
        dispatch({
          type: LIST_TARIF_SUCCESS,
          payload: response.data   
        })
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.message
        })
  
        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        dispatch({
          type: LIST_TARIF_FAIL
        })
  
        dispatch({
          type: SET_MESSAGE,
          payload: message
        })
  
        return Promise.reject()
      }
    )
  }

  
export const remove = (id) => (dispatch) => {
  return TarifService.deleteTarif(id).then(
    (response) => {
      console.log(response, 'delete');
      dispatch({
        type: DELETE_TARIF_SUCCESS,
        payload: id
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.data
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: DELETE_TARIF_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}


export const removeAll = () => (dispatch) => {
  return TarifService.deleteAll().then(
    (response) => {
      console.log(response, 'removeAll');
      dispatch({
        type: DELETE_ALL_TARIFS_SUCCESS,
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.data
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: DELETE_ALL_TARIFS_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}

export const edit = (id, data) => (dispatch) => {
  console.log(id, data);
  return TarifService.updateTarif(id, data).then(
    (response) => {
      console.log(response, 'edit');
      dispatch({
        type: UPDATE_TARIF_SUCCESS,
        payload: response.tarif
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.data
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: UPDATE_TARIF_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}


export const listTarifsBySize = (sizeId) => (dispatch) => {
  return TarifService.getTarifsBySize(sizeId).then(
    (response) => {
      console.log(response, 'ree');
      dispatch({
        type: LIST_TARIF_SUCCESS,
        payload: response.data   
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.message
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: LIST_TARIF_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}



export const tarifById = (tarifId) => (dispatch) => {
  return TarifService.getTarif(tarifId).then(
    (response) => {
      console.log(response, 'ree');
      dispatch({
        type: GET_TARIF_BY_ID_SUCCESS,
        payload: response.data   
      })

      dispatch({
        type: SET_MESSAGE,
        payload: response.message
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: GET_TARIF_BY_ID_FAIL
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message
      })

      return Promise.reject()
    }
  )
}
