import http from "./http-common";

const createCabine = (data) => {
  return http
    .post("/cabines/create", data)
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

const listCabine = () => {
  return http
    .get("/cabines/list")
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

const deleteAll = () => {
  return http
    .delete("/cabines")
    .then((response) => {
      console.log(response)
      return response
    })
    
}

const deleteCabine = (cabineId) => {
  return http
    .delete(`/cabines/${cabineId}`)
    .then((response) => {
      console.log(response)
      return response
    })
    
}

const update = (id, data) => {
  console.log(data);
  return http
    .put(`/cabines/update/${id}`, data)
    .then((response) => {
      return response.data
    })
}

const getCabine = (cabineId ) => {
  return http
    .post(`/main/getCabine/${cabineId }`)
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

export default {
  createCabine,
  listCabine,
  deleteAll,
  deleteCabine,
  update,
  getCabine,
};
