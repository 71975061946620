import http from "./http-common";

const createBox = (data, cabineId) => {
  return http
    .post(`/boxes/create/${cabineId}`, data)
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

const listBox = () => {
  return http
    .get("/boxes/list")
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

const deleteAll = () => {
  return http
    .delete("/boxes")
    .then((response) => {
      console.log(response)
      return response
    })
    
}

const deleteBox = (boxId) => {
  return http
    .delete(`/boxes/${boxId}`)
    .then((response) => {
      console.log(response)
      return response
    })
    
}

const update = (id, data) => {
  console.log(data);
  return http
    .put(`/boxes/update/${id}`, data)
    .then((response) => {
      return response.data
    })
}

const listBoxOnCabine = (cabineId) => {
  return http
    .get(`/boxes/${cabineId}`)
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

export default {
  createBox,
  listBox,
  deleteAll,
  deleteBox,
  update,
  listBoxOnCabine,
};
