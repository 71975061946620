import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { login } from "../../redux/actions/auth";
import { history } from "../../helpers/history";
import {useTranslation} from "react-i18next";

const Login = (props) => {

  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { message, status } = useSelector((state) => state.message);
  
  const validateEmail = (emailAdress) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    return !!emailAdress.match(regexEmail);
};

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
};

const onChangePassword = (e) => {
  const password = e.target.value;
  setPassword(password);
}

const handleLogin = (e) => {
  e.preventDefault();
  setLoading(true)
  console.log(validateEmail(email));
  console.log(loading);
  if(validateEmail(email) && email.length !== 0 && password.length > 3) {
    console.log(email, password);
    dispatch(login(email, password))
    .then(() => {
      setLoading(false)
      // window.location.reload();
      console.log(props.history);
      history.go("/admin/user-profile");
    })
    .catch(() => {
      console.log('***');
      setLoading(false);
    });
  }
};

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small> {t('common.contact_us')} </small>
            </div>

            <Form role="form" onSubmit={handleLogin}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={onChangeEmail}
                  />
                </InputGroup>
                {email.length !== 0 && !validateEmail(email) && loading ?
                            (<div className="error-input mt-2 ml-2"> {t('common.error_message_validation_email')} </div>) : null}
                {email.length === 0 && loading ?
                            (<div className="error-input mt-2 ml-2"> {t('common.error_message_required_email')}</div>) : null}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={onChangePassword}
                  />
                </InputGroup>
                {password.length < 5 && password.length > 0 && loading ?
                            (<div className="error-input mt-2 ml-2"> {t('common.error_message_validation_password')} </div>) : null}
                {password.length === 0 && loading ?
                            (<div className="error-input mt-2 ml-2"> {t('common.error_message_required_password')} </div>) : null}
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted"> {t('common.remember_me')} </span>
                </label>
              </div>
              {message && (
            <div className="form-group mt-4">
              <div  className={`alert ${status==='error' ? "alert-danger" : "alert-success"}`}  role="alert">
                {message}
              </div>
            </div>
          )}
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Connexion
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
        </Row>
      </Col>
    </>
  );
};

export default Login;
