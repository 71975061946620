export const CREATE_TARIF_SUCCESS = "CREATE_TARIF_SUCCESS";
export const CREATE_TARIF_FAIL = "CREATE_TARIF_FAIL";

export const LIST_TARIF_SUCCESS = "LIST_TARIF_SUCCESS";
export const LIST_TARIF_FAIL = "LIST_TARIF_FAIL";

export const DELETE_TARIF_SUCCESS = "DELETE_TARIF_SUCCESS"
export const DELETE_TARIF_FAIL = "DELETE_TARIF_FAIL"

export const DELETE_ALL_TARIFS_SUCCESS = "DELETE_ALL_TARIFS_SUCCESS"
export const DELETE_ALL_TARIFS_FAIL = "DELETE_ALL_TARIFS_FAIL"

export const UPDATE_TARIF_SUCCESS = "UPDATE_TARIF_SUCCESS"
export const UPDATE_TARIF_FAIL = "UPDATE_TARIF_FAIL"

export const GET_TARIF_BY_ID_SUCCESS = "GET_TARIF_BY_ID_SUCCESS"
export const GET_TARIF_BY_ID_FAIL = "GET_TARIF_BY_ID_FAIL"