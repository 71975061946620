import { SET_MESSAGE, CLEAR_MESSAGE } from "../../actions/message/type";
const initialState =  { message: '', status:'' } ;

const messageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
    console.log(payload);
      return { message: payload.message, status: payload.status };

    case CLEAR_MESSAGE:
      return { message: "", status: "" };

    default:
      return state;
  }
}
export default messageReducer