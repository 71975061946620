import { CREATE_TARIF_SUCCESS } from "redux/actions/tarifs/type";
import { DELETE_TARIF_SUCCESS } from "redux/actions/tarifs/type";
import { DELETE_ALL_TARIFS_SUCCESS } from "redux/actions/tarifs/type";
import { UPDATE_TARIF_SUCCESS } from "redux/actions/tarifs/type";
import { GET_TARIF_BY_ID_FAIL } from "redux/actions/tarifs/type";
import { GET_TARIF_BY_ID_SUCCESS } from "redux/actions/tarifs/type";
import { UPDATE_TARIF_FAIL } from "redux/actions/tarifs/type";
import { DELETE_ALL_TARIFS_FAIL } from "redux/actions/tarifs/type";
import { DELETE_TARIF_FAIL } from "redux/actions/tarifs/type";
import { CREATE_TARIF_FAIL } from "redux/actions/tarifs/type";
import { LIST_TARIF_FAIL } from "redux/actions/tarifs/type";
import { LIST_TARIF_SUCCESS } from "redux/actions/tarifs/type";

const initialState = {
  tarifs: [],
  currentTarif: null,
};

const tarifReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_TARIF_SUCCESS:
      return {
        ...state,
        tarifs: payload,
      };
    case LIST_TARIF_FAIL:
      return {
        ...state,
      };
      case GET_TARIF_BY_ID_SUCCESS:
        return {
          ...state,
          currentTarif: payload,
        };
      case GET_TARIF_BY_ID_FAIL:
        return {
          ...state,
        };
    case CREATE_TARIF_SUCCESS:
      return {
        ...state,
        tarifs: [...state.tarifs, payload],
      };
    case CREATE_TARIF_FAIL:
      return {
        ...state,
      };
    case DELETE_TARIF_SUCCESS:
      return {
        ...state,
        tarifs: state.tarifs.filter(({ id }) => id !== payload),
      };
    case DELETE_TARIF_FAIL:
      return {
        ...state,
      };
    case DELETE_ALL_TARIFS_SUCCESS:
      return {
        ...state,
        tarifs: [],
      };
    case DELETE_ALL_TARIFS_FAIL:
      return {
        ...state,
      };
    case UPDATE_TARIF_SUCCESS:
      console.log(payload, "edit tarif paylaod", state.tarifs);
      return {
        ...state,
        tarifs: state.tarifs.map((tarif) => {
          console.log(tarif.id === payload.id, "dddddddddddd");
          if (tarif.id === payload.id) {
            return {
              ...tarif,
              ...payload,
            };
          } else {
            return tarif;
          }
        }),
      };
    case UPDATE_TARIF_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default tarifReducer;
